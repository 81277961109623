<template>
  <div>
    <div class="d-flex align-center mb-4 h-35px px-1">
      <b-button-group class="ml-auto" size="sm">
        <button
          @click="addRule"
          class="btn btn-outline-info"
          :class="
            formatType == '2'
              ? currentQuery.length >= count
                ? 'disabled'
                : ''
              : ''
          "
        >
          <b-icon-diamond /> Add parameter
        </button>
      </b-button-group>
    </div>
    <template v-for="(item, index) in currentQuery">
      <Action
        :rule="item"
        :options="options"
        :format-type="formatType"
        :input-length="inputLength"
        :key="index"
        class="mt-2 px-1"
        ref="action"
      />
    </template>
  </div>
</template>

<script>
import Action from "@/own/components/warehouseManagement/dataParsing/actionBuilder/Action.vue";
export default {
  name: "ActionGroup",
  components: { Action },
  props: {
    currentQuery: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
    },
    formatType: {
      type: Number,
      required: true,
    },
    inputLength: {
      default: null,
    },
    count: {
      default: null,
    },
  },
  data: function () {
    return {};
  },
  mounted: function () {
    this.$nextTick(() => {
      if (this.currentQuery.length === 0) {
        this.addRule();
      }
    });
  },
  computed: {},
  methods: {
    addRule() {
      if (this.formatType == "2" && this.count <= this.currentQuery.length) {
        return;
      }
      const newGroup = {
        id: this.options[0].index,
        from: 0,
        to: 1,
        format: null,
      };
      this.currentQuery.push(newGroup);
      this.$nextTick(() => {
        this.$refs.action.forEach((comp) => {
          comp.$v.$touch();
        });
      });
    },
  },
};
</script>
